//-----------------------------------------------------------------
// Import of all Settings styles and any Overrides/Specfics
// ----------------------------------------------------------------

// TODO: Consolidate fonts imports (These fonts are also being imported in _settings.theme.scss with another TODO)
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdq.eot?#iefix') format('eot'), /* IE6-IE8 */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu.woff2') format('woff2'), /* Super Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdo.woff') format('woff'), /* Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr3cOWxw.ttf') format('truetype'), /* Safari, Android, iOS */
    url('//fonts.gstatic.com/l/font?kit=6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdp&skey=e2b26fc7e9e1ade8&v=v11#SourceSansPro') format('svg');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7h.eot?#iefix') format('eot'), /* IE6-IE8 */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2') format('woff2'), /* Super Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7j.woff') format('woff'), /* Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7gujVj9w.ttf') format('truetype'), /* Safari, Android, iOS */
    url('//fonts.gstatic.com/l/font?kit=6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7i&skey=1e026b1c27170b9b&v=v11#SourceSansPro') format('svg');
}

@font-face {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('//fonts.gstatic.com/s/sourceserifpro/v17/neIQzD-0qpwxpaWvjeD0X88SAOeauXQ-oAGIyY0.woff2') format('woff2');
}

// TODO: Move this new color to the PL
$redClay: #ce5633;

$fontFamily-sans-serif: "Source Sans Pro", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamily-serif: "Source Serif Pro", "Times New Roman", Times, Georgia, serif;

$section-breakpoint: lg;