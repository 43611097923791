$env: production;
@import '@/styles/_component-base.styles.scss';

.locationsRow {
    justify-content: center;

    @include breakpoint(md) {
        justify-content: flex-start;
    }
}

.heading {
    text-align: center;
}

.subheading {
    margin: auto;
    max-width: 600px;
    text-align: center;
    padding-top: 10px;
}

.hero {
    padding: spacer(2dot5xl) 0;

    @include breakpoint(md) {
        padding: spacer(5xl) 0;
    }
}

.locationTitle {
    font-weight: 500;
    line-height: 22px;
}

.image {
    width: 100%;
}

.regionButtons {
    margin-top: spacer(2xl);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: spacer(xs);
}

.regionButton {
    min-width: 0;
    border: none;
    border-radius: 5px;
    background: color(state, hover);
    padding: spacer(sm) spacer(base);

    &:hover, &:active, &:focus-visible {
        color: color(contrast, white);
        background-color: color(darkContrast, 3);
    }
}

.locationsByRegion {
    .locationTileContainer {
        align-items: center;
        margin-bottom: spacer(2xl);
    }

    .locationsRow {
        justify-content: center;
        margin-bottom: spacer(3xl);
    }

    .locationTile {
        width: 348px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: spacer(lg);
        border-radius: 5px;
        background-color: color(contrast, 2);

        @include breakpoint(md) {
            width: auto;
        }

        &:hover {
            .neighborhood {
                text-decoration: underline;
                color: color(darkContrast, 1);
            }
        }
    }

    .heading {
        display: flex;
        text-align: center;
        align-items: center;
        margin-bottom: spacer(2xl);

        &::before, &::after {
            flex: 1;
            content: '';
            width: 100%;
            height: 2px;
        }

        &::before {
            background: linear-gradient(90deg, #FFFFFF 0%, #F7F5F2 100%);
        }

        &::after {
            background: linear-gradient(90deg, #F7F5F2 0%, #FFFFFF 100%);
        }
    }

    .locationTitle {
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: spacer(xs);
        font-size: set-fontSize(16);
        color: color(text, headingDark);
        font-family: $fontFamily-sans-serif;
    }

    .image {
        width: 100%;
        height: 100%;
        margin-bottom: spacer(xs);
    }

    .neighborhood {
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        margin-bottom: spacer(xs);
        font-size: set-fontSize(20);
        color: color(text, headingDark);
        font-family: $fontFamily-serif;
    }

    .address {
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: color(text, base);
        font-size: set-fontSize(16);
        font-family: $fontFamily-sans-serif;
    }
}
