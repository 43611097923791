// TODO: evaluate these styles to be cleaned up and moved to pattern library
// TODO: update to official typo classes if Design System makes the full shift
$newPlTypo: (
    "heading2-refined": (
        "size": 24,
        "size-desktop": 32,
        "lineHeight": 1.2,
        "lineHeight-desktop": 1.2,
        "weight": 300,
        "transform": none,
        "color": color(text, base),
        "fontFamily": $fontFamily-serif,
    ),
    "heading1-refined": (
        "size": 36,
        "size-desktop": 40,
        "lineHeight": normal,
        "weight": 300,
        "transform": none,
        "color": color(text, base),
        "fontFamily": $fontFamily-serif,
    ),
    "heading6-refined": (
        "size": 14,
        "size-desktop": 16,
        "lineHeight": normal,
        "weight": 300,
        "letterSpacing": 3.5px,
        "transform": uppercase,
        "color": color(text, base),
        "fontFamily": $fontFamily-sans-serif,
    ),
);

@include makeTypoClasses($newPlTypo);

.u-displayBlock {
    display: block;
}

.t-fontWeight--normal {
    font-weight: 400;
}

.t-fontWeight--light {
    font-weight: 300;
}

.t-light {
    font-weight: $fontWeight-light;
}

.t-base {
    font-weight: $fontWeight-base;
}

// TODO: move classes into respective bit components
// Icon
.toolTip__iconCircle {
    fill: #6b6257;
}

.toolTip__iconClose {
    display: none;
    stroke: #fafafb;
}

.toolTip__iconInfo {
    fill: #fafafb;
}

// Imgix Picture
.lazyload-wrapper {
    height: inherit;
}
